import Image from 'next/image';
import clsx from 'clsx';

interface IImageWrapper {
    width: number;
    height: number;
    alt: string;
    src: string;
    imgClass?: string;
    containerClass?: string;
}

const ImageWrapper = ({
    width,
    height,
    alt = '',
    src,
    imgClass,
    containerClass,
}: IImageWrapper) => {
    return (
        <div
            className={clsx(
                'flex items-center justify-center relative overflow-hidden',
                containerClass,
            )}
        >
            <Image
                src={src}
                alt={alt}
                width={width}
                height={height}
                className={clsx(
                    'object-contain max-h-full object-center',
                    imgClass,
                )}
            />
        </div>
    );
};

export default ImageWrapper;
