'use client';

interface IExternalLink {
    href: string;
    children?: React.ReactNode;
    className?: string;
}

const ExternalLink = ({ children, className, href }: IExternalLink) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        //condition to prevent events from bubbling up from Link if it is inside
        if ((e.target as HTMLElement).tagName !== 'A') {
            e.preventDefault();
            window.open(href, '_blank');
        }
    };

    return (
        <button type="button" onClick={handleClick} className={className}>
            {children}
        </button>
    );
};

export default ExternalLink;
