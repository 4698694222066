interface ILoadMoreButton {
    width?: number;
    height?: number;
}

const LoadingIcon = ({ width = 16, height = 16 }: ILoadMoreButton) => (
    <div
        style={{ width: `${width}px`, height: `${height}px` }}
        className={`inline-block animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] md:w-12 md:h-12`}
        role="status"
    />
);

export default LoadingIcon;
