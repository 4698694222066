import Image from 'next/image';
import arrowDown from '@/public/icons/arrowDown.svg';
import LoadingIcon from '@/src/components/elements/LoadingIcon';

interface ILoadMoreButton {
    onClickLoad: () => void;
    isLoading: boolean;
}

const LoadMoreButton = ({ onClickLoad, isLoading }: ILoadMoreButton) => (
    <button
        type="button"
        onClick={onClickLoad}
        className="mt-12 mx-auto px-30 py-5 h-52 self-center rounded-md bg-secondary/50 border border-light text-white text-center text-base flex items-center gap-10 md:h-42"
    >
        Vis mer
        <div className="w-16 flex items-center justify-center md:w-12">
            {isLoading ? (
                <LoadingIcon width={16} height={16} />
            ) : (
                <Image
                    priority
                    src={arrowDown}
                    alt="arrow"
                    className="w-full"
                />
            )}
        </div>
    </button>
);

export default LoadMoreButton;
